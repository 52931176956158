<template>
  <div class="page">
    <div class="formcontainer staticpage">
      <div class="formbox">
        <h2>Temel Hizmetler</h2>

        <social />

        <div class="staticbox">
          <div>
              
          <div class="madde">
            <p>İYS’ye kaydolmuş tüm  <router-link to="/hizmetlerimiz" class="blue" ><strong>hizmet sağlayıcılar</strong></router-link >  Temel Hizmetler’den her zaman <strong>ücretsiz</strong> 
            olarak faydalanabilirler. </p>

<p>İYS Temel Hizmetler kapsamında hizmet sağlayıcılar, alıcıları (vatandaşlar) için ticari elektronik ileti izinlerini kendileri alır ve yönetir. İYS’de oluşan kayıtlar, hizmet sağlayıcının beyanına göre oluşturulur. Alıcının şikâyeti durumunda, onayın usule uygun alındığına ilişkin ispat yükümlülüğü hizmet sağlayıcıya aittir. </p>

<p>Temel Hizmetler kapsamında aşağıda listelenen tüm işlemler İYS web sitesi üzerinden gerçekleştirilir.</p>
</div>
          </div>

          <div class="full mt">
            <img src="@/assets/img/icons/temel-01.svg" alt />
          </div>

          <div class="madde">
            <div>
              <h3>I. İzin Ekleme / Değiştirme</h3>
              <p>İYS Temel Hizmetler kapsamında İYS web sitesi aracılığıyla yeni veya mevcut ticari elektronik ileti izinlerinde ekleme ve düzenleme yapmak mümkündür. Hizmet sağlayıcı, alıcılardan İYS dışında almış olduğu yeni bir izni veya mevcut bir izin için onay/ret değişikliği talebini İYS’ye kaydedebilir. 
                <router-link to="/iys/kanun" class="blue" ><strong>Mevzuat</strong></router-link > gereğince hizmet sağlayıcılar, alıcıdan gelen değişiklik taleplerini İYS'de 3 (üç) iş günü içinde güncellemekle yükümlüdür. 
              </p>
            </div>
          </div>
          
          <div class="madde">
            <div>
              <h3>II. İzin Durumu Sorgulama</h3>

              <p>Hizmet sağlayıcı adına çalışan yetkili kullanıcı, kayıtlı izinlerinde arama yapmak istediğinde izin yönetimi ekranındaki sorgulama alanını kullanır. Arama kutucuğuna alıcıya ait telefon numarası veya e-posta adresi yazılır. Aramanın başarılı olması için tüm telefon numarasının veya e-posta adresinin eksiksiz girilmesi gerekir. Sorgulama sonucunda alıcının izin durumu görüntülenir. </p>
  
            </div>
          </div>

          <div class="madde">
            <div>
              <h3>III. Marka ve Bayi/Acente Yönetimi</h3>
             <p>Hizmet sağlayıcı, alıcılardan aldığı izinleri İYS üzerinde tanımlayabileceği markaların altına ekler. Marka, hizmet sağlayıcıların alıcılara ticari elektronik ileti gönderirken kullandığı isimdir. Bu isim (marka) tescilli bir ibare olabileceği gibi hizmet sağlayıcının ticari unvanı da olabilir. </p>
<p>Hizmet sağlayıcı, İYS üzerinde birden fazla marka tanımlayabilir. Varsa bu markalara ait bayi/acente ağını İYS üzerinde oluşturabilir. Alıcılardan almış olduğu izinleri bu markalar altına ekleyebilir, bayileri için markalarına ait izinlere erişim verebilir. </p>
<p>Böylelikle alıcıların birbirinden bağımsız bir şekilde markalarına onay veya ret vermesini sağlayabilir. Ayrıca bayilerinin markalarına izin vermiş alıcılara ticari elektronik ileti gönderebilmesine imkân sağlamış olur.</p>

            </div>
          </div>



          <div class="madde">
            <div>
              <h3>IV. Günlük Raporlama</h3>
              <p>Hizmet sağlayıcı, her gün sonunda bir önceki gün içinde İYS de yer alan kayıtlara (hizmet sağlayıcının kayıtlı iletişim adreslerine) dair gerçekleşmiş değişiklikleri gösteren bir raporu alabilir. Temel Hizmetler kapsamındaki raporlama ekranında bu biçimde oluşturulmuş son 7 (yedi) güne ait rapora erişilebilir. 
              </p>
            </div>
          </div>          
        
        </div>
        
        <div class="nextpage">          
          <router-link to="/hizmet-saglayici/kurumsal-hizmetler">Kurumsal Hizmetler</router-link>
        </div>

        <!-- <div class="detailbox alt">
          <div class="infoline">
            <img src="@/assets/img/ico_info.svg" alt />
            <div class="desc">
              31 Aralık 2020 tarihine kadar mevcut izinlerin İleti Yönetim Sistemi
              üzerine aktarılması gerekmektedir. Bu tarihe kadar aktarılmayan
              izinler geçersiz sayılacaktır.
            </div> -->
            <!-- <div
              @click="SET_MODAL('ModalPreFaz'); SET_NOTIFY_REQUESTER_TYPE(1);"
              class="btn second"
            >
              <a>Beni Haberdar Et</a>
            </div>-->
            <!-- <div class="btn">
              <router-link to="/hizmet-saglayici/basvuru/nasil-yapilir">Hemen Başvur</router-link>
            </div>
          </div>
        </div> -->


      </div>
    </div>

    <div class="staticcolumn">
      <div>
        <!-- <h3>Mevzuat</h3>
        <div class="ucebir">
          <h4>E-Ticaret Kanunu</h4>
          <p>6563 sayılı Elektronik Ticaretin Düzenlenmesi Hakkında Kanun</p>
          <div class="btn second">
            <router-link to="/iys/kanun">Görüntüle</router-link>
          </div>
        </div>

        <div class="ucebir">
          <h4>15 Temmuz 2015 Tarihli Yönetmelik</h4>
          <p>Ti̇cari̇ İleti̇şi̇m ve Ti̇cari̇ Elektroni̇k İleti̇ler Hakkında Yönetmeli̇k</p>
          <div class="btn second">
            <router-link to="/iys/yonetmelik">Görüntüle</router-link>
          </div>
        </div>

        <div class="ucebir">
          <h4>04 Ocak 2020 Tarihli Yönetmelik</h4>
          <p>
            Ti̇cari̇ İleti̇şi̇m ve Ti̇cari̇ Elektroni̇k İleti̇ler Hakkında Yönetmeli̇kte
            Deği̇şi̇kli̇k Yapılmasına Dai̇r Yönetmeli̇k
          </p>
          <div class="btn second">
            <router-link to="/iys/yonetmelik-degisiklik">Görüntüle</router-link>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";

import Social from "../../genel/Social";

export default {
  name: "Homepage",
  components: { Social },

  methods: {
    ...mapMutations("app", ["SET_MODAL", "SET_NOTIFY_REQUESTER_TYPE"]),

    TANITIM_FILMI_CLICKED() {
      this.$router.push("/tanitim");
    },
  },
};
</script>

<style scoped>

.formcontainer {
    padding-top: 50px;
}

.madde p {margin-bottom: 10px;}

.staticbox > div {
  padding: 1vw 1vw 0 1vw;
  margin: 0;
  width: 100%;
  text-align: left;
}

ul li {
  display: list-item !important;
  list-style: square;
  margin: 15px 20px;
  justify-content: flex-start;
  line-height: 1.4;
  width: 100%;
}

h3 {
  margin-top: 5vh;
}

.staticpage h1 {
  margin-bottom: 2vh;
  padding-bottom: 0;
}

</style>
